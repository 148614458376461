import { Component }            from '@angular/core';
import {
    ActivatedRoute,
    Router 
}                               from '@angular/router';

import { ElementInfoComponent } from '../../element/element/element-info.component';

import { Survey }               from '../';

import { routeNames }           from '../../../app.routing.names';


@Component({
    selector:     'qp-survey-sighting-info',
    templateUrl:  'surveysighting-info.component.html',
    styleUrls:   ['surveysighting-info.component.css'],
    standalone: false
})
export class SurveySightingInfoComponent extends ElementInfoComponent
{
    constructor(ActRoute: ActivatedRoute,
                Router:   Router)
    {
        super(ActRoute, Router);
    }


    //
    // Getters
    //
    public get d(): Survey
    {
        return this.data as Survey; // [TBD]
    }

        //
    // Public methods
    //
    public selectedFn(d: any): Promise<boolean> | undefined
    {
        // Now route to main page
        return d instanceof Survey && (d as Survey).id
            ? this.Router.navigate(
                [ routeNames.surveys, (d as Survey).id ],
                { state: { parent: this.Router.url } }
            )
            : undefined;


            // this.Router.navigate([ routeNames.map ], { queryParams: { 'element': Survey.tag, 'id': this.data.id } });
    }
}