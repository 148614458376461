import { Component }            from '@angular/core';

import { IconsMaterial }        from '@Icons/';
import { OrganisationsService } from '@Organisations/organisations.service';

import { SideBarBaseComponent } from './sidebar-base.component';


@Component({
   selector:    'qp-sidebar-organisations',
   templateUrl: 'sidebar-organisations.component.html',
   styles:      'sidebar-organisations.component.css',
   standalone: false
})
export class SideBarOrganisationsComponent extends SideBarBaseComponent
{
    constructor(OrganisationsService: OrganisationsService)
    {
        super(IconsMaterial.organisations, OrganisationsService);
    }
}