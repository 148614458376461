import { Component }            from '@angular/core';

import { IconsMaterial }        from '@Icons/';

import { SideBarBaseComponent } from './sidebar-base.component';


@Component({
   selector:    'qp-sidebar-dashboard',
   templateUrl: 'sidebar-dashboard.component.html',
   styles:      'sidebar-dashboard.component.css',
   standalone: false
})
export class SideBarDashboardComponent extends SideBarBaseComponent
{
    constructor()
    {
        super(IconsMaterial.house);//IconsMaterial.dashboard;
    }
}