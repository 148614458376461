import {
    Component,
    Input
}                               from '@angular/core';

import { ElementCommon }              from '../element/element/';
import { ElementInfoComponent } from '../element/element/';
import { ListDataAttributes }   from '@GuiElements/';

import { 
    WifiCell,
    WifiVersion
}                               from './';


@Component({
    selector:     'qp-wifi-cell-info',
    templateUrl:  'wifi-cell-info.component.html',
    styleUrls:   ['wifi-cell-info.component.css'],
    standalone: false
})
export class WifiCellInfoComponent extends ElementInfoComponent
{
    @Input()
    public cell:        any;


    @Input()
    public nullValues:  boolean = true;
    

    @Input()
    public showVersion: boolean = true;


    @Input()
    public showLevel:   boolean = true;


    //
    // Getters
    //
    get d(): any
    {
        return this.cell;
    }


    get version(): WifiVersion | undefined
    {
        return WifiCellInfoComponent.version(this.cell);
    }


    get versionStr(): string | undefined
    {
        return WifiCellInfoComponent.versionStr(this.cell);
    }


    //
    // Public methods
    //
    public override listObj(d: ElementCommon): any[]
    {
        const o: any[] = WifiCellInfoComponent.listObj(d);
        return (o)
            ? o.map((e: ListDataAttributes) => ({
                k: e.name,
                v: e.value,
                c: e.class
            }))

            : super.listObj(d);
    }

    public static listObj(d: ElementCommon): ListDataAttributes[]
    {
        return (d instanceof WifiCell)
            ? [
                { name: 'Ssid',          value: d.ssid  ? d.ssid  : ''                                                    },
                { name: 'Bssid',         value: d.bssid ? d.bssid : ''                                                    },
                { name: 'Version',       value: this.versionStr(d),                           class: 'wifi-' + d.standard },
                { name: 'Band',          value: d.band  ? d.band.name : ''                                                },
                { name: 'Channel',       value: d.channel                                                                 },
                { name: 'Channel Width', value: d.channelwidth ? d.channelwidth + ' MHz' : ''                             },
                { name: 'Frequency',     value: d.frequencydl ? d.frequencydl + ' MHz' : ''                               },
                { name: 'Type',          value: d.standard                                                                },
                { name: 'Rssi',          value: d.rssi  ? d.rssi + ' dBm' : ''                                            }
            ]
            
            : [];
    }


    private static version(d: ElementCommon): WifiVersion | undefined
    {
        return (d instanceof WifiCell) ? d.version : undefined;
    }


    private static versionStr(d: ElementCommon): string | undefined
    {
        const v: WifiVersion | undefined = WifiCellInfoComponent.version(d);
        return (v) ? v.displayVersion : undefined;
    }

}