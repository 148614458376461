<div class="div" *ngIf="d">

    <qp-element-info [data]="d" [light]="light" [showDescription]=false [showType]=showType [showUpdated]=true></qp-element-info>

    <!-- <p></p>
    
    <h4 class="mat-subheading-1">{{ data.tag | titlecase }}</h4> -->
    <!-- <mat-list dense> -->

    <qp-table-data *ngIf="d" [nullValues]=false [data]="
        [
            showName
                ? { k: 'Name',        v: d.name        | titlecase                            }
                : {},
            { k: 'Type',              v: (d.description ? d.description : d.type) | titlecase },
            { k: 'Device Id',         v: d.deviceId                                           },
            { k: 'Device survey Id',  v: d.surveyId                                           },
            d.start
                ? { k: 'Start',       v: d.start + ''                                         }
                : {},
            d.end
                ? { k: 'End',         v: d.end + ''                                           }
                : {},
            (d.duration !== undefined)
                ? { k: 'Duration',    v: d.duration + ' secs'                                 }
                : {},
            { k: 'Number of points',  v: d.points?.length                                     }
        ]">
    </qp-table-data>
    <!-- d.end   ? { k: 'End',    v: d.end + ''                } : {}, -->


    <!-- <div class="mobilecells2" flex>
        <div class="mobilecells" fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutGap="0.5em grid" fxLayoutAlign="center">
            <div *ngFor="let s of mobilecellsArray()" fxFlex>
                <qp-mobile-cell-info
                    [light]="light" [matCard]=true [showMobileCellLevel]=false [cell]="s">
                </qp-mobile-cell-info>
            </div>
        </div>
    </div> -->

    <!-- <mat-list dense>
        <qp-data-element-item [light]="light" [key]="'Name'"            [text]="d.name        | titlecase"></qp-data-element-item>
        <qp-data-element-item [light]="light" [key]="'Description'"     [text]="d.description | titlecase"></qp-data-element-item>
        <qp-data-element-item [light]="light" [key]="'Start'"           [text]="d.start + ''">             </qp-data-element-item>
        <qp-data-element-item [light]="light" [key]="'End'"             [text]="d.end + ''">               </qp-data-element-item>
        <qp-data-element-item [light]="light" [key]="'Duration (secs)'" [text]="'' + d.duration">          </qp-data-element-item>
    </mat-list> -->

    <!-- <br><br> -->


    <!-- <qp-window-title [title]="'Survey Points'"></qp-window-title> -->
            <!-- <qp-window-title [title]="'Survey Points'" [value]="d.points.length"></qp-window-title> -->

    <!-- <div class="mobilecells" fxLayout="column wrap" fxLayout.lt-md="column" fxLayoutGap="0.5em grid" *ngIf="d.points">
        <div *ngFor="let v of d.points" fxFlex>
                <qp-survey-point-info
                    [light]="light" [matCard]=true [data]="v" [noCells]="d.points.length !== 0" (click)="selectedFn(v)">
                </qp-survey-point-info> -->
                    <!-- </mat-grid-tile> -->
        <!-- </div>
    </div> -->

</div>