import { MediaMatcher }  from '@angular/cdk/layout';
import {
    ChangeDetectorRef,
    Component,
    ViewChild
}                        from '@angular/core';
import {
    NavigationEnd,
    Router 
}                        from '@angular/router';

import {
    Observable,
    Subscription,

    filter
}                        from 'rxjs';

import { BaseComponent } from '@Base/';
import { IconsMaterial } from '@Icons/';
import {
    User,
    UserService
}                        from '@Login/';
import {
    MatDrawerMode,
    MatSidenav
}                        from '@Material/';


// import { SideBarService }       from './sidebar.service';


@Component({
   selector:     'qp-sidebar',
   templateUrl:  'sidebar.component.html',
   styleUrls:   ['sidebar.component.css'],
   standalone: false
})
export class SideBarComponent extends BaseComponent
{
    // @ViewChild(NgbTabset, {static: false})
    // private tabs: NgbTabset;

    private static readonly PermittedRole:  string = User.userRoles.administrator;
    private static readonly SidebarContent: string = 'sidebar-content'; // use for CSS class, needs to remove padding if map selected


    @ViewChild("sidenav")
    private         sideNav:               MatSidenav;

    // public readonly currentOrientation:    string = 'vertical';
    public          buttonClose:           boolean = false;
    public          buttonOpen:            boolean = false;
    public          data:                  any
    public          menuNarrow:            boolean;
    public          opened:                boolean = false;
    public          mobileQuery:           MediaQueryList;
    public          showBadge:             boolean = false;//true;
    public          showLabel:             boolean = true;
    public          screenNarrow:          boolean;
    public          sidenavContent:        string  = SideBarComponent.SidebarContent;

    public expandedDevices:boolean = false;
    public expandedSurveys:boolean = false;

    private         _activatedRoute:       string  = "";
    private         _user:                 User;
    private         _subRouter:            any;

    private         _mobileQueryListener: () => void;


    constructor(                 ChangeDetectorRef: ChangeDetectorRef,
                private readonly MediaMatcher:      MediaMatcher,
                private readonly Router:            Router,
                // private readonly TabsService: SideBarService,
                private readonly UserService:       UserService)
    {
        super();

        this.mobileQuery = MediaMatcher.matchMedia('(max-width: 599px)');
        this._mobileQueryListener = () => ChangeDetectorRef.detectChanges();
        
        this.screenNarrow = this.mobileQuery.matches;
        // this.menuNarrow = this.screenNarrow;

        if (this.mobileQuery?.addEventListener) this.mobileQuery.addEventListener('change', this._mobileQueryListener);
        else                                    this.mobileQuery.addListener(this._mobileQueryListener);
        // this.mobileQuery.onchange = (e) => {
        //     this.menuNarrow = (this.screenNarrow = this.mobileQuery.matches);
        //     this.opened = ! (this.screenNarrow) 
        //     this.ChangeDetectorRef.detectChanges();
        // }; // onchange()

        // this._mobileQueryListener = () => {
        //     this.sideNavNarrow = this.mobileQuery.matches;
        //     this.changeDetectorRef.detectChanges();
        // }
        // this.mobileQuery.addEventListener('change', this._mobileQueryListener);
        // // this.mobileQuery.addListener(this._mobileQueryListener);
    }


    // Override - don't let base class call this.initialise()
    public override ngOnInit(): void
    {
        // Call this.initialise() in ngAfterContentInit() to ensure view fully set up
    }

    onItemSelected(item: any) {
        // if (!item.children || !item.children.length) {
        //   this.router.navigate([item.route]);
        //   this.navService.closeNav();
        // }
        // if (item.children && item.children.length) {
        //   this.expanded = !this.expanded;
        // }
      }


    // Override
    public override ngAfterContentInit(): void // don't use ngAfterViewInit() as that can produce ExpressionChangedAfterItHasBeenCheckedError error
    {
        super.ngAfterContentInit();

        // Do here to call only after view fully set up
        this.initialise();
    }


    //
    // Getters and setters
    //
    public get activatedRoute(): string
    {
        return this._activatedRoute;
    }

    private set activatedRouteI(d: string)
    {
        this._activatedRoute = d ? d : "";
        this.sidenavContent = this.activatedRoute?.startsWith("/map") ? '' : SideBarComponent.SidebarContent;
    }


    //
    // Public methods
    //
    public allowed(): boolean
    {
        //return (user && user.userrole == TabsComponent.permittedRole);
        
        return false;// (this._user != null && this._user.userrole === SideBarComponent.PermittedRole);

        // [TBD] && user.userrole == TabsComponent.permittedRole);
    }


    public onClick(event: any): void
    {
        this.toggle();
    }


    // public toggle(): void
    // {
    //     this.screenNarrow = ! this.screenNarrow;
    // }

    public toggle(): void
    {
        // this.menuNarrow = ! this.menuNarrow;
        this.sideNav?.toggle()
    }


    //
    // Protected methods
    //

    // Override
    protected override cleanUp(): void
    {
        super.cleanUp()

        if (this._subRouter) this._subRouter.unsubscribe();
        if (this.mobileQuery?.removeEventListener) this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
        else                                       this.mobileQuery.removeListener(this._mobileQueryListener);

        // if (this.mobileQuery) this.mobileQuery.removeListener(this._mobileQueryListener);
    }


    // Override
    protected override initialise(): void
    {
        super.initialise();

        console.debug("Initialising SideBar component");

        // Subscribe to TabsService for updates
        // this.sub = this.TabsService.updatedTabActive.subscribe((id: string): void => {
        //     if (this.tabs && id) {
        //         console.log("Setting active tab: " + id);
        //         this.tabs.select(id);
        //     }
        // });

        // Subscribe for user updates
        const obs: Observable<any> | undefined = this.UserService.user$;
        if (obs instanceof Observable) this.sub = obs.subscribe((d: User): void => {
            this._user = d; 
        }); // subscribe


        // // Subscribe to SurveysService for updates
        // obs = this.SurveysService[DataServiceEvents.number];
        // if (obs instanceof Observable) this.sub = obs.subscribe((d: number): void => {
        //     if (this.surveysNum != d) {
        //         this.surveysNum = d;
        //         this.surveysColour = SideBarComponent.colourAccent;

        //         // Modify badge colour to indicate device number changed
        //         this.timerS = timer(SideBarComponent.timeout * 1000)
        //             .subscribe(() => {
        //                 this.surveysColour = SideBarComponent.colourPrimary;
        //                 this.timerS = undefined;
        //             }); // subscribe
        //     }
        // }); // subscribe


        // Get current route url; needed by HTML
        this.activatedRouteI = this.Router.url;
        this._subRouter = this.Router.events // seems to return SafeSubscriber, not Subscription
            .pipe(
                filter((event: any): boolean => event instanceof NavigationEnd)
            )
            .subscribe((d: any): void => {
                this.activatedRouteI = this.Router.url;
            }); // subscribe
    }
}