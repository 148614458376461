import {
    Pipe,
    PipeTransform
}                 from '@angular/core';


@Pipe({
    name: 'dataRate',
    pure: false,
    standalone: false
})
export class TestDataRatePipe implements PipeTransform
{
    transform(bps: number): string
    {
        if (isNaN(bps) || bps === 0) return '0 bps';
    
        const sizes = ['bps', 'Kbps', 'Mbps', 'Gbps', 'Tbps'];
        const i = Math.floor(Math.log(bps) / Math.log(1024));
        return `${parseFloat((bps / Math.pow(1024, i)).toFixed(2))} ${sizes[i]}`;
    }
}