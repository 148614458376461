import {
    Component,
    Input
}                               from '@angular/core';

import {
    MobileCell,
    MobileCellLevelComponent
}                               from '../mobile-cell/';

import { MobileCellUmtsCommon } from './';


@Component({
    selector:     'qp-mobile-cell-umts-level',
    templateUrl:  'mobile-cell-umts-level.component.html',
    styleUrls:   ['mobile-cell-umts-level.component.css'],
    standalone: false
})
export class MobileCellUmtsLevelComponent extends MobileCellLevelComponent
{
    //
    // Getters
    //

    public static override getLevelToolTip(d: MobileCell): string
    {
        return super.getLevelToolTip(d)
            + (d instanceof MobileCellUmtsCommon
                ? (d.rscp ? "\nRscp: " + d.rscp : "")
                    + (d.ecno ? "\nEcno: " + d.ecno  : "")
                
                : ""
            );
    }
}