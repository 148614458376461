import { Component }            from '@angular/core';
import { MediaObserver }        from '@angular/flex-layout';
import {
    ActivatedRoute,
    Router 
}                               from '@angular/router';

import { ElementInfoComponent } from '../../element/element/element-info.component';

import { DeviceMobilePhone }    from './device-mobilephone.class';


// import { MobileCellCommon as MobileCell }  from '../../mobile-cells/'; // [TBD]


@Component({
    selector:     'qp-device-mobilephone-info',
    templateUrl:  'device-mobilephone-info.component.html',
    styleUrls:   ['device-mobilephone-info.component.css'],
    standalone: false
})
export class DeviceMobilePhoneInfoComponent extends ElementInfoComponent
{
    constructor(                ActRoute: ActivatedRoute,
                                Router:   Router,
                
                public readonly Media:    MediaObserver)
    {
        super(ActRoute, Router);
    }


    //
    // Getters
    //
    public get d(): DeviceMobilePhone
    {
        return this.data as DeviceMobilePhone;
    }

    
    //
    // Public methods
    //
    public mobilecellsArray(): Object[]
    {
        return (this.d instanceof DeviceMobilePhone && Array.isArray(this.d.mobileCells))
            ? this.d.mobileCells.sort((a, b) => a.simslot - b.simslot)
            : [];
    }
}