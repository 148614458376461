export const AppStrings = {
    Device:            "Device",
    Devices:           "Devices",
    
    DeviceGroup:       "Device Group",
    DeviceGroups:      "Device Groups",

    Image:             "Image",
    Images:            "Images",

    Map:               "Map",
    
    Survey:            "Survey",
    Survey_GetReport:  "Get survey report",
    Survey_Report:     "Report",
    Surveys:           "Surveys",

    SurveyPoints:      "Points",
}