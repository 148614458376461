import {
    Component,
    Input
}                               from '@angular/core';

import { ElementInfoComponent } from '../../element/element/element-info.component';

import {
    MobileCell,
    MobileCellType
    // MobileTechnology
}                               from './';


@Component({
    selector:     'qp-mobile-cell-info',
    templateUrl:  'mobile-cell-info.component.html',
    styleUrls:   ['mobile-cell-info.component.css'],
    standalone: false
})
export class MobileCellInfoComponent extends ElementInfoComponent
{
    @Input()
    public cell:                     any;


    @Input()
    public nullValues:               boolean = true;
    

    @Input()
    public showMobileCellTechnology: boolean = true;


    @Input()
    public showMobileCellLevel:      boolean = true;


    //
    // Getters
    //
    get d(): any
    {
        return this.cell;
    }

    
    get mobileTechnology(): typeof MobileCellType//MobileCell.Technology
    {
        return MobileCellType;
        // return MobileCell.Technology;
    }


    get technology(): string
    {
        return (this.d instanceof MobileCell && this.d.technology instanceof MobileCellType)
            ? this.d.technology.type
            : "";
    }


    get technologyDetail(): string
    {
        return (this.d instanceof MobileCell)
            ? this.d.technologydetail
            : "";
    }
}