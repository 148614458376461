import { Component }            from '@angular/core';

import { IconsMaterial }        from '@Icons/';

import { SideBarBaseComponent } from './sidebar-base.component';


@Component({
   selector:    'qp-sidebar-menu',
   templateUrl: 'sidebar-menu.component.html',
   styles:      'sidebar-menu.component.css',
   standalone: false
})
export class SideBarMenuComponent extends SideBarBaseComponent
{
    constructor()
    {
        super(IconsMaterial.menu);
    }
}