// Minimum attributes required for passed-in object to Device class and its children
export interface DeviceI
{
	batteryCharging:  string,
	batteryConnected: string,
	batteryLevel:     string,
	id:               string,
	_id:              string,
    serialnumber:     string,
	_serialnumber:    string,
	type:             string
	_type:            string
}