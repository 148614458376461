import {
    Component,
    Input
}                               from '@angular/core';

import { ElementInfoComponent } from '../../element/element/element-info.component';

import {
    Test,
    TestTypes
    // MobileCellType
    // MobileTechnology
}                               from '../';


@Component({
    selector:     'qp-test-info',
    templateUrl:  'test-info.component.html',
    styleUrls:   ['test-info.component.css'],
    standalone: false
})
export class TestInfoComponent extends ElementInfoComponent
{
    @Input()
    public cell:                     any;


    @Input()
    public nullValues:               boolean = true;
    

    @Input()
    public showMobileCellTechnology: boolean = true;


    @Input()
    public showMobileCellLevel:      boolean = true;


    //
    // Getters
    //
    get d(): any
    {
        return this.cell;
    }

    
    get type(): any// MobileCellType//MobileCell.Technology
    {
        // console.error(this.d)
        // console.error(this.d?.type)
        return this.d?.type;//TestTypes
        // return MobileCellType;
        // return MobileCell.Technology;
    }


    // get technology(): string
    // {
    //     return (this.d instanceof MobileCell && this.d.technology instanceof MobileCellType)
    //         ? this.d.technology.type
    //         : "";
    // }


    // get technologyDetail(): string
    // {
    //     return (this.d instanceof MobileCell)
    //         ? this.d.technologydetail
    //         : "";
    // }
}