import {
    Component,
    Input
}                               from '@angular/core';

import { ElementInfoComponent } from '../../element/element/element-info.component';

import { MobileCellUmtsCommon } from './';


@Component({
    selector:     'qp-mobile-cell-umts-info',
    templateUrl:  'mobile-cell-umts-info.component.html',
    styleUrls:   ['mobile-cell-umts-info.component.css'],
    standalone: false
})
export class MobileCellUmtsInfoComponent extends ElementInfoComponent
{
    @Input()
    public cell:       MobileCellUmtsCommon;


    @Input()
    public nullValues: boolean               = true;



    //
    // Getters
    //
    get d(): any
    {
        return this.cell;
    }

    // get d(): MobileCellUmtsCommon
    // {
    //     return this.cell as MobileCellUmtsCommon;
    // }
}