import { Component }     from '@angular/core';

import { BaseComponent } from '@Base/';


@Component({
   selector:     'qp-tabs-container',
   templateUrl:  'tabs-container.component.html',
   styleUrls:   ['tabs-container.component.css'],
   standalone: false
})
export class TabsContainerComponent extends BaseComponent
{
}