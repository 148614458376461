<div class="sidebar-container" [class.example-is-mobile]="mobileQuery.matches">
    <!-- <mat-toolbar  class="example-toolbar" *ngIf="mobileQuery.matches">
        <button mat-icon-button (click)="sidenav.toggle()"><mat-icon>menu</mat-icon></button>
    </mat-toolbar> -->


    <mat-sidenav-container
    >
    <!-- [hasBackdrop]="false" -->
    <!-- autosize="true" -->


        <!-- [opened]="mobileQuery.matches ? false : true" -->
        <!-- [mode]="mobileQuery.matches ? 'over' : 'side' -->

        <!-- class="screenNarrow ? 'mat-sidenav-narrow' : (menuNarrow ? 'mat-sidenav-medium' : 'mat-sidenav-full')" -->

        <!-- [mode]="screenNarrow ? 'push' : 'side'" -->
        <!-- [mode]="menuNarrow ? 'over' : 'side'" -->

        <mat-sidenav #sidenav
            role="navigation"
            [opened]="! mobileQuery.matches"
            [disableClose]="! mobileQuery.matches"
            [mode]="mobileQuery.matches ? 'over' : 'side'"
            [position]="'start'"
            >
            <!-- buttonOpen ? 'over' : 'side'" -->

            <!-- [fixedInViewport]="mobileQuery.matches" -->

            <!-- [mode]="mobileQuery.matches ? 'over' : 'side'" -->


            <!-- <button mat-button (click)="toggle()" matTooltip="Navigation menu">
                <mat-icon>menu</mat-icon>
            </button> -->

            <!-- <qp-sidebar-menu (click)="toggle()"></qp-sidebar-menu> -->


            <mat-list role="list" class=icons *ngIf="! mobileQuery.matches || ! menuNarrow">

                <mat-list-item role="listitem">
                    <qp-sidebar-dashboard
                        class="dashboard-icon-style"
                        [active]="activatedRoute.startsWith('/dashboard')"
                        [showBadge]="showBadge"
                        [showLabel]="! menuNarrow && showLabel"
                        routerLink="/dashboard"
                        routerLinkActive="sidebar-active"
                        (click)="mobileQuery.matches ? sidenav.close() : undefined"
                        >
                    </qp-sidebar-dashboard>
                </mat-list-item>


                <mat-list-item role="listitem">
                    <qp-sidebar-organisations
                        class="dashboard-icon-style"
                        [active]="activatedRoute.startsWith('/organisations')"
                        [showBadge]="showBadge"
                        [showLabel]="! menuNarrow && showLabel"
                        routerLink="/organisations"
                        routerLinkActive="sidebar-active"
                        (click)="mobileQuery.matches ? sidenav.close() : undefined"
                        >
                    </qp-sidebar-organisations>
                </mat-list-item>

    <!-- 
                <button mat-button [ngClass]="activatedRoute.startsWith('/organisations') ? 'mat-button-active' : ''" color="primary" matTooltip="Organisations (Quantity: {{ organisationsNum }})" routerLink="/organisations">
                <mat-icon [matBadge]="organisationsNum" matBadgePosition="below after" matBadgeSize="medium" [matBadgeHidden]="organisationsNum <= 0" aria-label="Organisations view">{{ organisationsIcon }}</mat-icon>
                </button> -->


                <mat-list-item role="listitem">
                    <qp-sidebar-surveys       
                        class="dashboard-icon-style"
                        [active]="activatedRoute.startsWith('/surveys')"
                        [showBadge]="showBadge"
                        [showLabel]="! menuNarrow && showLabel"
                        routerLink="/surveys"
                        routerLinkActive="sidebar-active"
                        (click)="mobileQuery.matches ? sidenav.close() : undefined"
                        >
                    </qp-sidebar-surveys>
                </mat-list-item>

                <!-- @if (expandedDevices) {
                    <mat-icon fxFlex *ngIf="! expandedSurveys">
                    expand_more
                    </mat-icon>
                } -->

                <!-- <mat-list-item role="listitem" (click)="expandedSurveys = ! expandedSurveys">
                    <div style="display: flex;">
                        <qp-sidebar-surveys fxFlex
                            class="dashboard-icon-style"
                            [active]="activatedRoute.startsWith('/surveys')"
                            [showBadge]="showBadge"
                            [showLabel]="! menuNarrow && showLabel"
                            [showExpansion]="false"
                            routerLinkActive="sidebar-active"
                            (click)="mobileQuery.matches ? sidenav.close() : undefined"
                            >
                        </qp-sidebar-surveys>
                        <span fxFlex></span>
                        @if (expandedSurveys) {
                            <mat-icon fxFlex>
                                keyboard_arrow_up
                            </mat-icon>
                        }
                        @else {
                            <mat-icon fxFlex>
                                keyboard_arrow_down
                            </mat-icon>
                        }
                    </div>
                </mat-list-item>
                <div *ngIf="expandedSurveys">
                    <mat-list-item>
                        <button mat-menu-item routerLink="/surveys" [queryParams]="{running: true}" class="subList">
                            running
                        </button>
                    </mat-list-item>
                    <mat-list-item>
                        <button mat-menu-item routerLink="/surveys" class="subList">
                            all
                        </button>
                    </mat-list-item>
                  </div> -->
<!-- 
                <mat-menu #surveys="matMenu" class="menu" [overlapTrigger]="false" xPosition="after" yPosition="below">
                    <ng-template matMenuContent>
                        <button mat-menu-item routerLink="/surveys" [queryParams]="{running: true}" class="subList">
                            running
                        </button>
                        <button mat-menu-item routerLink="/surveys" class="subList">
                            all
                        </button>
                    </ng-template>
                </mat-menu> -->
                  
                <!-- <mat-accordion multi> -->
                <!-- <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <qp-sidebar-surveys       
                            class="dashboard-icon-style"
                            [active]="activatedRoute.startsWith('/surveys')"
                            [showBadge]="showBadge"
                            [showLabel]="! menuNarrow && showLabel"
                            routerLinkActive="sidebar-active"
                            (click)="mobileQuery.matches ? sidenav.close() : undefined"
                            >
                        </qp-sidebar-surveys>
                        </mat-expansion-panel-header>

                        <ng-template matExpansionPanelContent>
                            <mat-list role="list">
                                <mat-list-item role="listitem" routerLink="/surveys" [queryParams]="{running: true}" class="subList">
                                    running
                                </mat-list-item>

                                <mat-list-item role="listitem" routerLink="/surveys" class="subList">
                                    all
                                </mat-list-item>
                            </mat-list>
                        </ng-template>
                    </mat-expansion-panel> -->


                <!-- <mat-list-item role="listitem" [matMenuTriggerFor]="devices"> -->
                <mat-list-item role="listitem" (click)="expandedDevices = ! expandedDevices">
                    <div style="display: flex;">
                        <qp-sidebar-devices       
                            class="dashboard-icon-style"
                            [active]="activatedRoute.startsWith('/devices')"
                            [showBadge]="showBadge"
                            [showLabel]="! menuNarrow && showLabel"
                            [showExpansion]="false"
                            routerLinkActive="sidebar-active"
                            (click)="mobileQuery.matches ? sidenav.close() : undefined"
                            >
                        </qp-sidebar-devices>
                        <span fxFlex></span>
                        @if (expandedDevices) {
                            <mat-icon fxFlex>
                            <!-- [@indicatorRotate]="expandedDevices ? 'expanded': 'collapsed'"> -->
                               keyboard_arrow_up
                            </mat-icon>
                        }
                        @else {
                            <mat-icon fxFlex>
                                <!-- [@indicatorRotate]="expandedDevices ? 'expanded': 'collapsed'"> -->
                                keyboard_arrow_down
                            </mat-icon>
                        }
                    </div>
                </mat-list-item>
                <div *ngIf="expandedDevices">
                    <mat-list-item>
                        <!-- <button mat-menu-item routerLink="/devices" [queryParams]="{online: true}" class="subList"> -->
                        <button mat-menu-item routerLink="/devices/online" class="subList">
                            online
                        </button>
                    </mat-list-item>
                    <mat-list-item>
                        <button mat-menu-item routerLink="/devices" class="subList">
                            all
                        </button>
                    </mat-list-item>
                </div>

              <!-- <mat-menu #devices="matMenu" class="menu" [overlapTrigger]="false" xPosition="after" yPosition="below">
                <ng-template matMenuContent>
                    <button mat-menu-item routerLink="/devices" [queryParams]="{online: true}" class="subList">
                        running
                    </button>
                    <button mat-menu-item routerLink="/devices" class="subList">
                        all
                    </button>
                </ng-template>
            </mat-menu> -->


                    <!-- <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <qp-sidebar-devices       
                                class="dashboard-icon-style"
                                [active]="activatedRoute.startsWith('/devices')"
                                [showBadge]="showBadge"
                                [showLabel]="! menuNarrow && showLabel"
                                routerLinkActive="sidebar-active"
                                (click)="mobileQuery.matches ? sidenav.close() : undefined"
                                >
                            </qp-sidebar-devices>
                        </mat-expansion-panel-header>

                        <ng-template matExpansionPanelContent>
                            <mat-list role="list" >
                                <mat-list-item role="listitem" routerLink="/devices" [queryParams]="{online: true}" class="subList">
                                    online
                                </mat-list-item>

                                <mat-list-item role="listitem" routerLink="/devices" class="subList">
                                    all
                                </mat-list-item>
                            </mat-list>
                        </ng-template>
                    </mat-expansion-panel>
                </mat-accordion> -->


                <mat-list-item role="listitem" *ngIf="allowed">
                    <qp-sidebar-devicegroups       
                        class="dashboard-icon-style"
                        [active]="activatedRoute.startsWith('/devicegroups')"
                        [showBadge]="showBadge"
                        [showLabel]="! menuNarrow && showLabel"
                        routerLink="/devicegroups"
                        routerLinkActive="sidebar-active"
                        (click)="mobileQuery.matches ? sidenav.close() : undefined"
                        >
                    </qp-sidebar-devicegroups>
                </mat-list-item>


                <mat-list-item role="listitem" *ngIf="! allowed">
                    <qp-sidebar-map
                        class="dashboard-icon-style"
                        [active]="activatedRoute.startsWith('/map')"
                        [showBadge]="showBadge"
                        [showLabel]="! menuNarrow && showLabel"
                        routerLink="/map"
                        routerLinkActive="sidebar-active"
                        (click)="mobileQuery.matches ? sidenav.close() : undefined"
                        >
                    </qp-sidebar-map>
                </mat-list-item>


                <mat-list-item role="listitem" *ngIf="! allowed">
                    <qp-sidebar-mobilecells
                        class="dashboard-icon-style"
                        [active]="activatedRoute.startsWith('/mobilecells')"
                        [showBadge]="showBadge"
                        [showLabel]="! menuNarrow && showLabel"
                        routerLink="/mobilecells"
                        routerLinkActive="sidebar-active"
                        (click)="mobileQuery.matches ? sidenav.close() : undefined"
                        >
                    </qp-sidebar-mobilecells>
                </mat-list-item>


                <mat-list-item role="listitem" *ngIf="! allowed">
                    <qp-sidebar-wifiaps
                        class="dashboard-icon-style"
                        [active]="activatedRoute.startsWith('/wifiaps')"
                        [showBadge]="showBadge"
                        [showLabel]="! menuNarrow && showLabel"
                        routerLink="/wifiaps"
                        routerLinkActive="sidebar-active"
                        (click)="mobileQuery.matches ? sidenav.close() : undefined"
                        >
                    </qp-sidebar-wifiaps>
                </mat-list-item>
            </mat-list>
            
        </mat-sidenav>

        
        <mat-sidenav-content [ngClass]="sidenavContent">
            <!-- <div class="flex2"> -->
                <router-outlet></router-outlet>
            <!-- </div> -->
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>