import { Component }             from '@angular/core';
import { MatDialogRef}           from "@angular/material/dialog";
import { Subscription }          from 'rxjs';

import { BaseComponent }         from '@Base/';
import { InsightsWindowService } from '@Insights/';
// import { qp_WindowService }      from '@Utils-ajs/';

import { NotificationsService }  from './notifications.service';


@Component({
   selector:     'qp-notifications-old',
   templateUrl:  'notifications-old.component.html',
   styleUrls:   ['notifications-old.component.css'],
   standalone: false
})
export class NotificationsOldComponent extends BaseComponent
{
    public Object = Object; // required for HTML to use Object.keys()

    public readonly name:  string = "Notifications";

    public compareAvailable           = true;
    public compareCheckboxesAvailable = false;

    public dataAttrs: any[]           = [];
    public data: any[]                = [];
    public selected: any              = {};

    // Used for user-configurable table sorting
    public propertyName: any  = "name";
    public doReverse     = false;
    public search        = '';
    public currentPage   = 1;

    public newPageNumber: number;
    public oldPageNumber: number;

    private clearedAlarms = false;
    private static readonly alarmStatusPeriod = 10; // secs

    constructor(private readonly NotificationsService:    NotificationsService,
                // private readonly qp_WindowFactory:        qp_WindowService,
                private readonly qp_WindowInsightFactory: InsightsWindowService,
                private readonly dialogRef:               MatDialogRef<NotificationsOldComponent>)
    {
        super();
    }

         
    //
    // Public functions
    //
    public buttonCleared(): void
    {
        console.log("Checkbox selected - refreshing notifications (including cleared: " + this.clearedAlarms + ")");
        this.refresh(this.clearedAlarms);
    }


    public close(): void
    {
        if (this.dialogRef) this.dialogRef.close();
    }


        // $scope.$on('networkkpialarms', function(event, data)
        // {
        //     if (! data.kpialarms || data.kpialarms.length < 1) {
        //         console.log("Received null network KPI alarms");
        //     }
        //     else {
        //         console.log("Received network KPI alarms - update list: " + data.kpialarms.length);
        //         updateTable(data.kpialarms);
        //         var kpi = data.kpialarms[data.kpialarms.length - 1];
        //         qp_StatusFactory.setStatus("KPI alarm " + kpi.id + ": " +  kpi.operator + " (" + kpi.dataConnectionType + "), " + kpi.kpiName + " = " + kpi.kpiValue + "," + alarmStatusPeriod); // show status msg of latest alarm for 10 secs

        //         ctrl.networkNames = qp_NetworkScoreFactory.getNetworks(true);
        //         if (! ctrl.myOperator) {
        //             //ctrl.changeMyOperator(getDefaultOperator(ctrl.networkNames));
        //         }
        //         //updateQoECharts();
        //     }
        // });


        // function updateTable(alarms) 
        // {
        //     //console.log(alarms);

        //     // Convert datetime to local format
        //     for (var i = 0, len = alarms.length; i < len; ++i) {
        //         if (alarms[i].createdDate) {
        //             alarms[i].createdDate = new Date(alarms[i].createdDate.toLocaleString());
        //         }
        //     }
        //     // Use timeout (of 0s) to ensure Angular notifed so it can trigger apply()/digest() for live screen updatng
        //     qp_TimeoutFactory.processViaTimeout(function() {
        //         ctrl.kpiAlarms.length = 0;
        //         //if (! this.kpiAlarmsAttrs || this.kpiAlarmsAttrs.length <= 0) {
        //             ctrl.kpiAlarmsAttrs = Object.keys(alarms[0]);
        //         //}
        //         ctrl.kpiAlarms = alarms;
        //     });
        // }

    public getNum(): number
    {
        return this.NotificationsService.getNumNotifications();
    }


    public pageChanged(new_: number, old: number): void
    {
        console.log("Page changed: " + new_ + " to " + old);
    }


    public refresh(cleared?: boolean): void
    {
        this.NotificationsService.refresh(cleared);
    }


    public showDetails($event: any, dev: any): void
    {
        if (dev) {
            //console.log(dev);
            console.log("Device '" + dev.serialNum.val + "' selected for details");
           // this.qp_WindowFactory.showWindow($event, 'qp_DeviceInfoController', 'window-device-info.html', dev, null, null);//, angular.element(document.querySelector('#test')) );
        }
    }


    public sortBy(propertyName2: string): void
    {
        const propertyName: string = propertyName2;
        this.doReverse             = (this.propertyName === propertyName) ? ! this.doReverse : false;
        this.propertyName          = propertyName;
    }


    //
    // Protected functions
    //
    protected override initialise(): void
    {
        super.initialise();

        console.log("Initialising Notifications Controller");

        // Subscribe to DeviceGroups service for updates
        this.sub = this.NotificationsService.updatedNum.subscribe((num: any) => {
            this.update();
        });
    }


    //
    // Private functions
    //
    private update(): void
    {
        this.data = this.NotificationsService.get;
    }
}