import { Component }     from '@angular/core';

import { BaseComponent } from '@Base/';


@Component({
   selector:     'qp-sidebar-container',
   templateUrl:  'sidebar-container.component.html',
   styleUrls:   ['sidebar-container.component.css'],
   standalone: false
})
export class SideBarContainerComponent extends BaseComponent
{
   
}