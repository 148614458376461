import { Component }            from '@angular/core';
import {
    ActivatedRoute,
    Router 
}                               from '@angular/router';

import { ElementInfoComponent } from '../element/element/element-info.component';

import { 
    Device, 
    DeviceTypes
}                               from './device/';


@Component({
    selector:     'qp-device-info-container',
    templateUrl:  'device-info-container.component.html',
    styleUrls:   ['device-info-container.component.css'],
    standalone: false
})
export class DeviceInfoContainerComponent extends ElementInfoComponent
{
    public readonly DeviceTypes: any = DeviceTypes;


    constructor(ActRoute: ActivatedRoute,
                Router:   Router)
    {
        super(ActRoute, Router);
    }


    //
    // Getters
    //
    public get d(): Device
    {
        return this.data as Device;
    }
}